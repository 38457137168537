import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import ForecastGraphComponent from './ForecastGraphComponent';
import MarkersComponent from './MarkersComponent'; // Keep MarkersComponent for markers data
import { MarkersData } from './MarkersData'; // Import marker data with correct casing

const getColorForDeviceType = (deviceType) => {
  switch (deviceType) {
    case 'Substation':
      return '#FF0000'; // Red for substations
    case 'Mini-Substation':
      return '#FFA500'; // Orange for mini-substations
    case 'Feeder':
      return '#00FF00'; // Green for feeders
    case 'Inverter':
      return '#0000FF'; // Blue for inverters
    case 'Meter':
      return '#FFFF00'; // Yellow for meters
    default:
      return '#FFFFFF'; // Default color
  }
};

function Forecasting({ isCollapsed }) {
  const mapContainerRef = useRef(null); // Reference for the map container
  const mapRef = useRef(null); // Reference for the Mapbox instance
  const drawRef = useRef(null); // Reference for the MapboxDraw instance
  const [mapLoaded, setMapLoaded] = useState(false); // Track if map is loaded
  const [markers, setMarkers] = useState([]); // Store marker references
  const [polygons, setPolygons] = useState([]); // Store polygons in state
  const [activeTab, setActiveTab] = useState('load'); // Track active tab
  const [selectedCustomer, setSelectedCustomer] = useState(null); // State for selected marker (customer)
  const [isDrawing, setIsDrawing] = useState(false); // Drawing mode state

  // Initialize the Mapbox map
  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN; // Replace with your Mapbox token

      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current, // ID of the map container
        style: 'mapbox://styles/mapbox/streets-v11', // Map style
        center: [24.9916, -30.5595], // Initial center of the map
        zoom: 4.5 // Initial zoom level
      });

      // Initialize MapboxDraw for drawing polygons
      drawRef.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true
        }
      });

      // Add draw controls to the map
      mapRef.current.addControl(drawRef.current);

      mapRef.current.on('load', () => {
        setMapLoaded(true); // Mark the map as loaded
        loadSavedPolygons(); // Load saved polygons when the map is ready
        mapRef.current.on('draw.create', handlePolygonCreate); // Handle polygon creation event
      });
    }
  }, [mapContainerRef]);

  // Trigger map resize when the isCollapsed state changes
  useEffect(() => {
    if (mapRef.current) {
      // Slight delay to ensure layout updates before resizing the map
      setTimeout(() => {
        mapRef.current.resize(); // Ensure the map resizes when layout changes
      }, 300); // Adjust this delay if necessary
    }
  }, [isCollapsed]);

  useEffect(() => {
    if (mapLoaded) {
      addMarkers(); // Add markers to the map when it is loaded
    }
  }, [mapLoaded, activeTab]);

  const handlePolygonCreate = (e) => {
    const polygonGeoJson = e.features[0]; // Get the drawn polygon's GeoJSON

    // Add default name and description to the polygon properties
    polygonGeoJson.properties = {
      name: 'Unnamed Polygon',
      description: ''
    };

    // Save to LocalStorage
    let savedPolygons = JSON.parse(localStorage.getItem('polygons') || '[]');
    savedPolygons.push(polygonGeoJson);
    localStorage.setItem('polygons', JSON.stringify(savedPolygons));

    attachPopupToPolygon(polygonGeoJson); // Attach popup functionality to the new polygon
  };

  // Load saved polygons from LocalStorage
  const loadSavedPolygons = () => {
    const savedPolygons = JSON.parse(localStorage.getItem('polygons') || '[]');
    savedPolygons.forEach((polygonGeoJson) => {
      const polygonId = `polygon-${polygonGeoJson.id}`;
      mapRef.current.addSource(polygonId, { type: 'geojson', data: polygonGeoJson });
      mapRef.current.addLayer({
        id: polygonId,
        type: 'fill',
        source: polygonId,
        paint: {
          'fill-color': 'rgba(200, 100, 240, 0.4)',
          'fill-outline-color': 'rgba(200, 100, 240, 1)'
        }
      });
      attachPopupToPolygon(polygonGeoJson); // Attach popup to the loaded polygon
    });
  };

  // Attach popup functionality to polygons
  const attachPopupToPolygon = (polygonGeoJson) => {
    mapRef.current.on('click', `polygon-${polygonGeoJson.id}`, (e) => {
      const popup = new mapboxgl.Popup()
        .setLngLat(e.lngLat) // Position the popup at the click location
        .setHTML(`
          <h3>Edit Polygon Info</h3>
          <label for="polygonName">Name:</label>
          <input type="text" id="polygonName" value="${polygonGeoJson.properties.name}" maxlength="50"/>
          <label for="polygonDescription">Description:</label>
          <input type="text" id="polygonDescription" value="${polygonGeoJson.properties.description}" maxlength="50"/>
          <button id="savePolygonInfo">Save</button>
          <button id="deletePolygonInfo" style="background-color: red; color: white;">Delete</button>
        `)
        .addTo(mapRef.current);

      // Save and delete functionality inside the popup
      setTimeout(() => {
        const saveButton = document.getElementById('savePolygonInfo');
        const deleteButton = document.getElementById('deletePolygonInfo');

        // Save polygon information
        if (saveButton) {
          saveButton.addEventListener('click', () => {
            const newName = document.getElementById('polygonName').value;
            const newDescription = document.getElementById('polygonDescription').value;

            // Update polygon properties
            polygonGeoJson.properties.name = newName;
            polygonGeoJson.properties.description = newDescription;

            // Save to LocalStorage
            let savedPolygons = JSON.parse(localStorage.getItem('polygons') || '[]');
            const polygonIndex = savedPolygons.findIndex(polygon => polygon.id === polygonGeoJson.id);
            if (polygonIndex !== -1) {
              savedPolygons[polygonIndex] = polygonGeoJson;
              localStorage.setItem('polygons', JSON.stringify(savedPolygons));
            }

            popup.remove(); // Close the popup after saving
          });
        }

        // Delete polygon
        if (deleteButton) {
          deleteButton.addEventListener('click', () => {
            let savedPolygons = JSON.parse(localStorage.getItem('polygons') || '[]');
            const updatedPolygons = savedPolygons.filter(polygon => polygon.id !== polygonGeoJson.id);
            localStorage.setItem('polygons', JSON.stringify(updatedPolygons));

            const polygonId = `polygon-${polygonGeoJson.id}`;
            if (mapRef.current.getSource(polygonId)) {
              mapRef.current.removeLayer(polygonId);
              mapRef.current.removeSource(polygonId);
            }

            popup.remove(); // Close the popup after deletion
          });
        }
      }, 200); // Delay to ensure the buttons are available
    });
  };

  const addMarkers = () => {
    markers.forEach(marker => marker.remove());

    const newMarkers = MarkersData.map((markerData) => {
      const markerColor = getColorForDeviceType(markerData.deviceType);

      const markerElement = document.createElement('div');
      markerElement.className = 'marker';
      markerElement.style.backgroundColor = markerColor;
      markerElement.style.width = '20px';
      markerElement.style.height = '20px';
      markerElement.style.borderRadius = '50%';

      const avgLoad = calculateAverage(markerData.loadData);

      const marker = new mapboxgl.Marker({ element: markerElement })
        .setLngLat(markerData.position)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }).setHTML(
            `<h3>${markerData.name}</h3>
             <p>Location: ${markerData.city}</p>
             <p>Network Level: ${markerData.deviceType}</p>
             <p>Avg Hourly Load: ${avgLoad}kWh</p>`
          )
        )
        .addTo(mapRef.current);

      return marker;
    });

    setMarkers(newMarkers);
  };

  const toggleDrawingMode = () => {
    if (isDrawing) {
      drawRef.current.changeMode('simple_select');
    } else {
      drawRef.current.changeMode('draw_polygon');
    }
    setIsDrawing(!isDrawing);
  };

  const onMarkerClick = (marker) => {
    setSelectedCustomer(marker);
  };

  const calculateAverage = (loadData) => {
    if (!loadData || loadData.length === 0) return 0;
    const sum = loadData.reduce((acc, curr) => acc + curr, 0);
    return (sum / loadData.length).toFixed(2);
  };

  return (
    <div className="forecasting-container">
      <h2 className="forecasting-title">Forecasting</h2>

      <div className="filter-container">
        <div className="dropdown-container">
          <label htmlFor="clientDropdown">Client</label>
          <select id="clientDropdown" name="client">
            <option value="client1">Client 1</option>
            <option value="client2">Client 2</option>
          </select>
        </div>
        <div className="dropdown-container">
          <label htmlFor="dateRangeDropdown">Date</label>
          <input type="date" id="dateRangeDropdown" name="dateRange" />
        </div>
      </div>

      <div className="map-container" style={{ height: '500px' }}>
        <div ref={mapContainerRef} id="map" style={{ height: '100%', width: '100%' }}></div>
      </div>

      <div className="tabs-and-buttons">
        <div className="tabs">
          <button className={activeTab === 'load' ? 'active' : ''} onClick={() => setActiveTab('load')}>
            Load
          </button>
          <button className={activeTab === 'generation' ? 'active' : ''} onClick={() => setActiveTab('generation')}>
            Generation
          </button>
        </div>
        <div className="button-container">
          <button className="forecast-button">Regenerate Forecast</button>
          <button className="download-button">Download</button>
          <button className="download-button" onClick={toggleDrawingMode}>
            {isDrawing ? 'Stop Drawing' : 'Start Drawing'}
          </button>
        </div>
      </div>

      {mapRef.current && <MarkersComponent map={mapRef.current} onMarkerClick={onMarkerClick} />}

      {selectedCustomer && (
        <div className="customer-info">
          <h3>Study Area</h3>
          <p><strong>Name:</strong> {selectedCustomer.name}</p>
          <p><strong>Location:</strong> {selectedCustomer.city}</p>
          <p><strong>Network Level(s):</strong> {selectedCustomer.deviceType}</p>
        </div>
      )}

{selectedCustomer && (
  <>
    {activeTab === 'load' && (
      <ForecastGraphComponent
        activeTab={activeTab}
        loadData={Array.isArray(selectedCustomer.loadData) ? selectedCustomer.loadData : []}  // Fallback to empty array if loadData is null/undefined
        productionData={Array.isArray(selectedCustomer.productionData) ? selectedCustomer.productionData : []}
        selectedMarker={selectedCustomer}
      />
    )}

    {activeTab === 'generation' && (
      Array.isArray(selectedCustomer.productionData) && selectedCustomer.productionData.length > 0 ? (
        <ForecastGraphComponent
          activeTab={activeTab}
          loadData={Array.isArray(selectedCustomer.loadData) ? selectedCustomer.loadData : []}
          productionData={selectedCustomer.productionData}
          selectedMarker={selectedCustomer}
        />
      ) : (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <p>No production data available for the selected network node.</p>
        </div>
      )
    )}
  </>
)}


    </div>
  );
}

export default Forecasting;