import React, { useState } from 'react';
import FormComponent from './FormComponent';
import FormComponentAlt from './FormComponentAlt';
import OutputComponent from './OutputComponent';
import AWS from 'aws-sdk';

function OnDemand() {
  const [activeTab, setActiveTab] = useState('api');
  const [activeSubTab, setActiveSubTab] = useState('quickstart');
  const [response, setResponse] = useState(''); // State to store API response
  const [logs, setLogs] = useState(''); // State to store combined CloudWatch logs
  const [polling, setPolling] = useState(false); // State for polling logs
  const [apiCallTimestamp, setApiCallTimestamp] = useState(null); // Track when API call was made

  // AWS SDK setup (Configure using .env variables)
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const cloudWatchLogs = new AWS.CloudWatchLogs();

  // Function to fetch logs from a single CloudWatch log group
  const fetchLogGroupLogs = (logGroupName, sinceTimestamp) => {
    return new Promise((resolve, reject) => {
      const params = {
        logGroupName,
        orderBy: 'LastEventTime', // Order by the last event time to get the latest logs
        descending: true,
      };
  
      cloudWatchLogs.describeLogStreams(params, (err, data) => {
        if (err) {
          reject('Error fetching log streams: ' + err);
        } else if (data.logStreams && data.logStreams.length > 0) {
          const logStreamName = data.logStreams[0].logStreamName;
  
          const logParams = {
            logGroupName,
            logStreamName,
            startTime: sinceTimestamp, // Only filter logs by startTime here in getLogEvents
            startFromHead: true,
          };
  
          cloudWatchLogs.getLogEvents(logParams, (err, data) => {
            if (err) {
              reject('Error fetching CloudWatch logs: ' + err);
            } else {
              const logMessages = data.events.map((event) => ({
                timestamp: event.timestamp,
                message: event.message,
              }));
              resolve(logMessages); // Return logs with timestamp
            }
          });
        } else {
          resolve([]); // No logs found
        }
      });
    });
  };
  

  // Function to fetch logs from both log groups and merge them
  const fetchCombinedLogs = async (sinceTimestamp) => {
    try {
      // Fetch logs from both log groups
      const logs1 = await fetchLogGroupLogs(process.env.REACT_APP_LOG_GROUP_1, sinceTimestamp); // ingestHistoricalLoadData
      const logs2 = await fetchLogGroupLogs(process.env.REACT_APP_LOG_GROUP_2, sinceTimestamp); // trainForecastModel

      // Combine and sort logs by timestamp
      const combinedLogs = [...logs1, ...logs2].sort((a, b) => a.timestamp - b.timestamp);

      // Format logs into a readable string
      const formattedLogs = combinedLogs.map((log) => {
        const date = new Date(log.timestamp).toLocaleString();
        return `[${date}] ${log.message}`;
      }).join('\n');

      setLogs(formattedLogs); // Set the combined logs in the state
    } catch (error) {
      setLogs('Error fetching combined logs: ' + error);
    }
  };

  // Polling function to continuously check logs
  const startPollingLogs = (sinceTimestamp) => {
    setPolling(true);
  
    const fetchedLogIds = new Set(); // Track fetched logs to avoid duplicates
    let retryCount = 0;
  
    let pollInterval = setInterval(async () => {
      await fetchCombinedLogs(sinceTimestamp)
        .then((newLogs) => {
          // Filter out already fetched logs
          const filteredLogs = newLogs.filter(log => !fetchedLogIds.has(log.timestamp));
  
          // Add new log entries to the set
          filteredLogs.forEach(log => fetchedLogIds.add(log.timestamp));
  
          // Check if the logs contain completion indicators for both log groups
          const combinedLogs = filteredLogs.map((log) => log.message).join('\n');
          setLogs((prevLogs) => prevLogs + '\n' + combinedLogs);
  
          // Conditions for Log Group 1 (ingestHistoricalLoadData)
          if (combinedLogs.includes("Model training Lambda triggered") || 
              combinedLogs.includes("Error triggering model training Lambda:") || 
              combinedLogs.match(/END RequestId: [\w-]+/)) {
            console.log('Polling for Log Group 1 Complete');
            clearInterval(pollInterval); // Stop polling for Log Group 1
          }
  
          // Conditions for Log Group 2 (trainForecastModel)
          if (combinedLogs.includes("Model training completed with status:") || 
              combinedLogs.match(/END RequestId: [\w-]+/)) {
            console.log('Polling for Log Group 2 Complete');
            clearInterval(pollInterval); // Stop polling for Log Group 2
          }
        })
        .catch((error) => {
          console.error("Error fetching logs: ", error);
          retryCount++;
          if (retryCount >= 3) {
            clearInterval(pollInterval); // Stop polling after retries fail
            setPolling(false);
          }
        });
    }, 5000); // Poll every 5 seconds (adjust as needed)
  
    // Add initial delay to account for CloudWatch log propagation
setTimeout(() => {
  // Start the interval after the delay
  pollInterval = setInterval(async () => {
    await fetchCombinedLogs(sinceTimestamp);
    // Rest of your polling logic
  }, 5000);
}, 5000);

  };
  

  const handleApiResponse = (data) => {
    const currentTimestamp = Date.now(); // Store the timestamp when the API call is made
    setApiCallTimestamp(currentTimestamp);
    setResponse(data); // Update response when received from FormComponent

    // Start polling logs from both log groups after the API response is received
    startPollingLogs(currentTimestamp);
  };

  const renderSubContent = () => {
    switch (activeSubTab) {
      case 'quickstart':
        return (
          <div className="code-container">
            <h3>Python Example</h3>
            <p>Our API's were designed for easy integration within existing technology stacks.</p>
            <p>Here is a quick and easy implementation of our historical data ingest API with Python</p>
            <pre>
              <code>
                {`import requests
api_key = 'YOUR_API_KEY'
base_url = 'https://api.asoba.co/v1/upload'
endpoint = 'historical'
headers = {
  'Authorization': f'Bearer {api_key}',
  'Content-Type': 'text/csv'
}
payload = {
  'location': 'Cape Town',
  'filename': '/path/to/csv',
  'customer_id': '280001'
}
response = requests.post(f'{base_url}{endpoint}', headers=headers, json=payload)
if response.status_code == 200:
  print('Success:', response.json())
else:
  print('Error:', response.status_code, response.text)
`}
              </code>
            </pre>
          </div>
        );
      case 'learnmore':
        return <p>This is the Learn More content.</p>;
      case 'utilityinverter':
        return <p>This is the Getting Utility/Inverter Data content.</p>;
      case 'apidocumentation':
        return <p>This is the API Documentation content.</p>;
      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'api':
        return (
          <div className="container">
            <div className="form-container">
              <h2>Input</h2>
              {/* Pass handleApiResponse to FormComponent */}
              <FormComponent onResponse={handleApiResponse} />
            </div>
            <div className="output-container">
              <h2>Output</h2>
              {/* Pass the response and logs to OutputComponent */}
              <OutputComponent response={response} logs={logs} />
            </div>
          </div>
        );
      case 'api2':
        return (
          <div className="container">
            <div className="form-container">
              <h2>Input</h2>
              <FormComponentAlt />
            </div>
            <div className="output-container">
              <h2>Output</h2>
              <OutputComponent response={response} logs={logs} />
            </div>
          </div>
        );
      case 'guide':
        return (
          <div className="getting-started">
            <div className="left-tabs">
              <ul>
                <li
                  onClick={() => setActiveSubTab('quickstart')}
                  className={activeSubTab === 'quickstart' ? 'active' : ''}
                >
                  Quick Start
                </li>
                <li
                  onClick={() => setActiveSubTab('learnmore')}
                  className={activeSubTab === 'learnmore' ? 'active' : ''}
                >
                  Learn More
                </li>
                <li
                  onClick={() => setActiveSubTab('utilityinverter')}
                  className={activeSubTab === 'utilityinverter' ? 'active' : ''}
                >
                  Getting Utility/Inverter Data
                </li>
              </ul>
            </div>
            <div className="content-area">{renderSubContent()}</div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="on-demand">
      <div className="tabs">
        <button
          className={activeTab === 'api' ? 'active' : ''}
          onClick={() => setActiveTab('api')}
        >
          Train Model
        </button>
        <button
          className={activeTab === 'api2' ? 'active' : ''}
          onClick={() => setActiveTab('api2')}
        >
          Run Inference
        </button>
        <button
          className={activeTab === 'guide' ? 'active' : ''}
          onClick={() => setActiveTab('guide')}
        >
          Getting Started
        </button>
      </div>
      {renderContent()}
    </div>
  );
}

export default OnDemand;