import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from 'react';
import Profile from './Profile';
import { FaPencilAlt } from 'react-icons/fa';
import Modal from './Modal';
import AWS from 'aws-sdk';  // AWS SDK for interacting with API Gateway


const Settings = () => {
  const [activeTab, setActiveTab] = useState('user');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalType, setModalType] = useState('');
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();  // Get Auth0 user
  const [clientId, setClientId] = useState(null);  // Store clientid for current user
  const [apiKeys, setApiKeys] = useState([]);  // For storing API keys
  const [newApiKeyName, setNewApiKeyName] = useState('');  // For creating new API keys

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  // Add state for collapsible section
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);
  const apiGateway = new AWS.APIGateway({ region: 'af-south-1' });  // Initialize AWS API Gateway
  const apiUrl = process.env.REACT_APP_API_URL; // Base URL from your .env for auth0

  // Fetch the clientid based on the Auth0 sub
  const fetchClientId = async () => {
    try {
      const { sub } = user;  // Get the Auth0 sub (unique user identifier)

      // Fetch the clientid from your backend using the Auth0 sub
      const response = await fetch(`${apiUrl}/get-clientid`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getAccessTokenSilently()}`,  // Include Auth0 token for auth
        },
        body: JSON.stringify({ sub })  // Send the Auth0 sub to your backend
      });

      const data = await response.json();
      setClientId(data.clientid);  // Set the clientid in state
      console.log('Client ID fetched successfully:', data.clientid);
    } catch (error) {
      console.error('Error fetching clientid:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      fetchClientId();  // Fetch clientid after login
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (clientId) {
      fetchApiKeysForUser();  // Fetch API keys after clientid is fetched
    }
  }, [clientId]);

  // Fetch users and roles when Admin tab is active
  useEffect(() => {
    if (activeTab === 'admin') {
      fetchUsers();
      fetchRoles();
    }
  }, [activeTab]);

  // Fetch users from AWS API Gateway
  const fetchUsers = async () => {
    try {
      const response = await fetch(`${apiUrl}/users`);
      const usersData = await response.json();
      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Fetch roles from AWS API Gateway
  const fetchRoles = async () => {
    try {
      const response = await fetch(`${apiUrl}/roles`);
      const rolesData = await response.json();
      setRoles(rolesData);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  

  // Toggle the collapsible section
  const toggleCollapsible = () => {
    setIsCollapsibleOpen(!isCollapsibleOpen);
  };

  // Create a new API key using AWS API Gateway
  const createApiKey = async (formData) => {
    const { apiKeyName } = formData;
    console.log('Sending API key request with name:', apiKeyName);  // Debugging point
  
    try {
      const apiKeyResponse = await apiGateway.createApiKey({
        name: apiKeyName,
        enabled: true,
        generateDistinctId: true,  // Ensures uniqueness
      }).promise();
  
      console.log('API Key created successfully:', apiKeyResponse);  // Debug the response
  
      const apiKeyId = apiKeyResponse.id;
  
      // Step 2: Associate API Key with a Usage Plan
      await apiGateway.createUsagePlanKey({
        keyId: apiKeyId,
        keyType: 'API_KEY',
        usagePlanId: '63wc53',  // Your usage plan ID
      }).promise();

      // Update Postgres with API key details
      await updateApiKeyInDatabase({
        apiKeyName,
        apiKeyValue: apiKeyResponse.value,  // This is the actual key value
      });
  
      setApiKeys([...apiKeys, apiKeyResponse]);  // Add new API key to the state
    } catch (error) {
      console.error('Error creating API key:', error);  // Catch and log any errors
    }
  };
  


  // Open modal for creating/editing users or roles
  const openModal = (type, entryData = null) => {
    setModalType(type);
    setIsEditMode(!!entryData);
    setModalData(entryData);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Save new or updated entry (User or Role)
  const saveEntry = async (newData) => {
    console.log('saveEntry called with:', newData);  // Debugging point
    if (modalType === 'API Key') {
      await createApiKey(newData);  // Create API key in AWS
      await updateApiKeyInDatabase(newData);  // Update API key in Postgres
    } else if (modalType === 'User') {
      if (isEditMode) {
        await updateUser(newData);
      } else {
        await createUser(newData);
      }
    } else if (modalType === 'Role') {
      if (isEditMode) {
        await updateRole(newData);
      } else {
        await createRole(newData);
      }
    }
    closeModal();
  };
  
  

  // Create a new user using API Gateway and Lambda
  const createUser = async (formData) => {
    const { email, password } = formData;
    try {
      const response = await fetch(`${apiUrl}/create-user`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, connection: 'Username-Password-Authentication' }),
      });
      const result = await response.json();
      console.log('User created:', result);
      fetchUsers();  // Refresh users list after creation
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  // Update an existing user using API Gateway and Lambda
  const updateUser = async (formData) => {
    const { email, password } = formData;
    try {
      const response = await fetch(`${apiUrl}/update-user`, {
        method: 'POST',  // Using POST as per your API setup
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, connection: 'Username-Password-Authentication' }),
      });
      const result = await response.json();
      console.log('User updated:', result);
      fetchUsers();  // Refresh users list after update
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  // Create a new role using API Gateway and Lambda
  const createRole = async (formData) => {
    const { name, description } = formData;
    try {
      const response = await fetch(`${apiUrl}/create-role`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, description }),
      });
      const result = await response.json();
      console.log('Role created:', result);
      fetchRoles();  // Refresh roles list after creation
    } catch (error) {
      console.error('Error creating role:', error);
    }
  };

  // Update an existing role using API Gateway and Lambda
  const updateRole = async (formData) => {
    const { name, description } = formData;
    try {
      const response = await fetch(`${apiUrl}/update-role`, {
        method: 'POST',  // Using POST as per your API setup
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, description }),
      });
      const result = await response.json();
      console.log('Role updated:', result);
      fetchRoles();  // Refresh roles list after update
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  const renderAdminContent = () => {
    return (
      <div className="admin-tab">
        {/* Admin Section for User Management */}
        <div className="section-header">
          <h3>Users</h3>
          <a className="add-link" onClick={() => openModal('CreateUser')}>+ Add User</a>
        </div>
  
        {/* Users Table (Styled similarly to other tables) */}
        <table className="client-details-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.user_id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>
                  <a onClick={() => openModal('EditUser', user)} className="edit-icon">
                    <FaPencilAlt />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
  
        {/* Spacer between Users Table and Roles Table */}
        <div className="spacer"></div>
  
        {/* Admin Section for Role Management */}
        <div className="section-header">
          <h3>Roles</h3>
          <a className="add-link" onClick={() => openModal('CreateRole')}>+ Add Role</a>
        </div>
  
        {/* Roles Table (Styled similarly to other tables) */}
        <table className="client-details-table">
          <thead>
            <tr>
              <th>Role</th>
              <th>Description</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role) => (
              <tr key={role.id}>
                <td>{role.name}</td>
                <td>{role.description}</td>
                <td>
                  <a onClick={() => openModal('EditRole', role)} className="edit-icon">
                    <FaPencilAlt />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };


  // Function to fetch API keys for the current user
  // Fetch API keys for the logged-in user
  const fetchApiKeysForUser = async () => {
    try {
      if (!clientId) {
        console.error('Client ID not available, skipping API key fetch');
        return;
      }

      const response = await fetch(`${apiUrl}/api-keys/${clientId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${await getAccessTokenSilently()}`,  // Auth header with Auth0 token
        },
      });

      const apiKeysData = await response.json();
      setApiKeys(apiKeysData);  // Update the apiKeys state with the fetched data
    } catch (error) {
      console.error('Error fetching API keys:', error);
    }
  };

// Function to update the client_api_keys table in Postgres
const updateApiKeyInDatabase = async (apiKeyData) => {
  try {
    const userId = 'currentuserid';  // Replace with actual user ID logic
    const response = await fetch(`${apiUrl}/update-client-api-keys`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        clientId: userId,
        apiKeyName: apiKeyData.apiKeyName,
        apiKey: apiKeyData.apiKeyValue,
      }),
    });
    if (response.ok) {
      console.log('API key successfully updated in the database');
      fetchApiKeysForUser();  // Refresh the list of API keys
    } else {
      console.error('Failed to update API key in database');
    }
  } catch (error) {
    console.error('Error updating API key in database:', error);
  }
};


    // Function to render the API keys table
  const renderApiKeysTable = () => (
    <table className="client-details-table">
      <thead>
        <tr>
          <th>API Key Name</th>
          <th>API Key</th>
        </tr>
      </thead>
      <tbody>
        {apiKeys.map((key) => (
          <tr key={key.id}>
            <td>{key.name}</td>
            <td>{key.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  // Function to open the modal for creating API key
  const openApiKeyModal = () => {
    setNewApiKeyName('');  // Reset modal input
    setModalType('API Key');
    setIsModalOpen(true);  // Open modal
  };


  const renderContent = () => {
    switch (activeTab) {
      case 'customers':
        return (
          <div className="customer-tab">
            {/* Clients Section Header */}
            <div className="section-header">
              <h2>Clients</h2>
              <a className="add-link" onClick={() => openModal('Client')}>+ Add Client</a>
            </div>
  
            <div className="customer-details-container">
              <div className="dropdown-container">
                <div className="dropdown-left">
                  <label htmlFor="clientSelect">Client</label>
                  <select id="clientSelect" name="client">
                    <option value="">Name</option>
                  </select>
                </div>
              </div>
  
              {/* Clients Table */}
              <table className="client-details-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Placeholder 1</th>
                    <th>Placeholder 2</th>
                    <th>Placeholder 3</th>
                    <th>Placeholder 4</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>Placeholder</td>
                    <td>Placeholder</td>
                    <td>Placeholder</td>
                    <td>Placeholder</td>
                    <td>
                      <a onClick={() => openModal('Client', { name: 'Name' })} className="edit-icon">
                        <FaPencilAlt />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
  
              {/* Spacer between Clients Table and collapsible title */}
              <div className="spacer"></div>
            </div>
            <br /><br />
  
            {/* Collapsible section */}
            <a className="service-link" onClick={toggleCollapsible}>
              Service Providers, Customers, & Devices {isCollapsibleOpen ? '▲' : '▼'}
            </a>
  
            {isCollapsibleOpen && (
              <div className="collapsible-section">
                {/* Service Providers Section */}
                <div className="section-header">
                  <h3>Service Providers</h3>
                  <a className="add-link" onClick={() => openModal('ServiceProvider')}>+ Add Service Provider</a>
                </div>
  
                <div className="service-provider-table">
                  <div className="provider-row">
                    <p><strong>Name:</strong> Provider Name</p>
                    <p><strong>ID:</strong> 1234567890</p>
                    <p><strong>Address:</strong> 123 Cherry Street</p>
                    <p><strong>Contact Name:</strong> Tshepo Mashego</p>
                    <p><strong>Contact Email:</strong> tshepo@acme.co</p>
                    <p><strong>Contact Phone:</strong> 66778967</p>
                    <a onClick={() => openModal('ServiceProvider', { providerName: 'Provider Name', address: '123 Cherry Street' })} className="edit-icon">
                      <FaPencilAlt />
                    </a>
                  </div>
                </div>
  
                {/* Customers Table */}
                <div className="section-header">
                  <h3>Customers</h3>
                  <a className="add-link" onClick={() => openModal('Customer')}>+ Add Customer</a>
                </div>
                <table className="customers-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Service Provider</th>
                      <th>Number of Meters</th>
                      <th>Grid Interconnection</th>
                      <th>Monthly Generation Capacity (kWh)</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>Provider</td>
                      <td>30</td>
                      <td>Yes</td>
                      <td>200</td>
                      <td>
                        <a onClick={() => openModal('Customer', { name: 'Customer', provider: 'Provider', meters: 30, capacity: 200 })} className="edit-icon">
                          <FaPencilAlt />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
  
                {/* Devices Section */}
                <div className="section-header">
                  <h3>Devices</h3>
                  <a className="add-link" onClick={() => openModal('Device')}>+ Add Device</a>
                </div>
                <table className="device-table">
                  <thead>
                    <tr>
                      <th>Serial #</th>
                      <th>Status</th>
                      <th>Manufacturer</th>
                      <th>Model</th>
                      <th>Device Type</th>
                      <th>Nameplate Capacity</th>
                      <th>Peak Load Capacity</th>
                      <th>Monthly Production</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>123456789</td>
                      <td><span className="status">Active</span></td>
                      <td>ESKOM</td>
                      <td>Model</td>
                      <td>Solar</td>
                      <td>5</td>
                      <td>5</td>
                      <td>10,000</td>
                      <td>
                        <a onClick={() => openModal('Device', { serial: '123456789', status: 'Active', manufacturer: 'ESKOM' })} className="edit-icon">
                          <FaPencilAlt />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        );
      case 'user':
        return <Profile />;
      case 'integrations':  // Integrations case for managing API keys
        return (
          <div className="integrations-tab">
            <h2>API Integrations</h2>
            {/* Render the API Keys Table */}
            {renderApiKeysTable()}
            {/* Link to open modal for creating API key */}
            <a className="add-link" onClick={openApiKeyModal}>+ Create API Key</a>
          </div>
        );
      case 'admin':
        return (
          <div className="admin-tab">
            {/* Admin Section for User Management */}
            <div className="section-header">
              <h3>Users</h3>
              <a className="add-link" onClick={() => openModal('CreateUser')}>+ Add User</a>
            </div>
    
            {/* Users Table (Styled similarly to other tables) */}
            <table className="client-details-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.user_id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>
                      <a onClick={() => openModal('EditUser', user)} className="edit-icon">
                        <FaPencilAlt />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
    
            {/* Spacer between Users Table and Roles Table */}
            <div className="spacer"></div>
    
            {/* Admin Section for Role Management */}
            <div className="section-header">
              <h3>Roles</h3>
              <a className="add-link" onClick={() => openModal('CreateRole')}>+ Add Role</a>
            </div>
    
            {/* Roles Table (Styled similarly to other tables) */}
            <table className="client-details-table">
              <thead>
                <tr>
                  <th>Role</th>
                  <th>Description</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {roles.map((role) => (
                  <tr key={role.id}>
                    <td>{role.name}</td>
                    <td>{role.description}</td>
                    <td>
                      <a onClick={() => openModal('EditRole', role)} className="edit-icon">
                        <FaPencilAlt />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      default:
        return null;
    }
  };
  

  return (
    <div className="settings">
      <div className="tabs">
        <button className={activeTab === 'user' ? 'active' : ''} onClick={() => setActiveTab('user')}>User</button>
        <button className={activeTab === 'customers' ? 'active' : ''} onClick={() => setActiveTab('customers')}>Account Management</button>
        <button className={activeTab === 'integrations' ? 'active' : ''} onClick={() => setActiveTab('integrations')}>Integrations</button>
        <button className={activeTab === 'admin' ? 'active' : ''} onClick={() => setActiveTab('admin')}>Admin</button>
      </div>
      <div className="settings-content">
        {renderContent()}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSave={saveEntry}
        entryData={modalData}
        isEdit={isEditMode}
        type={modalType}
      />
    </div>
  );
};

export default Settings;
