// src/components/Dashboard.js
import React from 'react';

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
      <p>This is the Dashboard content.</p>
    </div>
  );
}

export default Dashboard;
