import React from 'react';

function OutputComponent({ response, logs }) {
  return (
    <div className="output-content">
      <div className="response-container">
        <h2>Response:</h2>
        {/* Force the inline styles to handle text wrapping */}
        <div className="response-window" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', maxWidth: '100%', overflowY: 'auto' }}>
          <pre style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{response ? JSON.stringify(response, null, 2) : 'No response yet'}</pre>
        </div>
      </div>
      <div className="logs-container">
        <h2>Logs:</h2>
        {/* Handle line breaks and tabs cleanly */}
        <div className="logs-window" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', maxWidth: '100%', overflowY: 'auto' }}>
          <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{logs ? logs : 'No logs available yet'}</pre>
        </div>
      </div>
    </div>
  );
}

export default OutputComponent;
