import React from 'react';

const Modal = ({ isOpen, onClose, onSave, entryData, isEdit, type }) => {
  if (!isOpen) {
    return null;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted');  // Add this for debugging
    const formData = new FormData(event.target);
    const updatedData = Object.fromEntries(formData);
    onSave(updatedData);
  };
  
  

  const renderFormFields = () => {
    switch (type) {
      // Existing form fields
      case 'Customer':
        return (
          <>
            <div className="form-group">
              <label>Name:</label>
              <input type="text" name="name" defaultValue={entryData?.name || ''} required />
            </div>
            <div className="form-group">
              <label>Service Provider:</label>
              <input type="text" name="provider" defaultValue={entryData?.provider || ''} required />
            </div>
            <div className="form-group">
              <label>Number of Meters:</label>
              <input type="number" name="meters" defaultValue={entryData?.meters || ''} required />
            </div>
            <div className="form-group">
              <label>Monthly Generation Capacity (kWh):</label>
              <input type="number" name="capacity" defaultValue={entryData?.capacity || ''} required />
            </div>
          </>
        );
      case 'Device':
        return (
          <>
            <div className="form-group">
              <label>Serial #:</label>
              <input type="text" name="serial" defaultValue={entryData?.serial || ''} required />
            </div>
            <div className="form-group">
              <label>Status:</label>
              <input type="text" name="status" defaultValue={entryData?.status || ''} required />
            </div>
            <div className="form-group">
              <label>Manufacturer:</label>
              <input type="text" name="manufacturer" defaultValue={entryData?.manufacturer || ''} required />
            </div>
            <div className="form-group">
              <label>Model:</label>
              <input type="text" name="model" defaultValue={entryData?.model || ''} required />
            </div>
            <div className="form-group">
              <label>Device Type:</label>
              <input type="text" name="deviceType" defaultValue={entryData?.deviceType || ''} required />
            </div>
            <div className="form-group">
              <label>Nameplate Capacity:</label>
              <input type="number" name="nameplateCapacity" defaultValue={entryData?.nameplateCapacity || ''} required />
            </div>
          </>
        );
      case 'ServiceProvider':
        return (
          <>
            <div className="form-group">
              <label>Provider Name:</label>
              <input type="text" name="providerName" defaultValue={entryData?.providerName || ''} required />
            </div>
            <div className="form-group">
              <label>Address:</label>
              <input type="text" name="address" defaultValue={entryData?.address || ''} required />
            </div>
            <div className="form-group">
              <label>Contact Name:</label>
              <input type="text" name="contactName" defaultValue={entryData?.contactName || ''} required />
            </div>
            <div className="form-group">
              <label>Contact Email:</label>
              <input type="email" name="contactEmail" defaultValue={entryData?.contactEmail || ''} required />
            </div>
            <div className="form-group">
              <label>Contact Phone:</label>
              <input type="text" name="contactPhone" defaultValue={entryData?.contactPhone || ''} required />
            </div>
          </>
        );
      case 'API Key':  // Add handling for API Key creation
        return (
          <>
            <div className="form-group">
              <label>API Key Name:</label>
              <input type="text" name="apiKeyName" defaultValue={entryData?.name || ''} required />
            </div>
          </>
        );
      case 'Client':
        return (
          <>
            <div className="form-group">
              <label>Client Name:</label>
              <input type="text" name="clientName" defaultValue={entryData?.clientName || ''} required />
            </div>
          </>
        );
      
      // New User management case
      case 'User':
        return (
          <>
            <div className="form-group">
              <label>Email:</label>
              <input type="email" name="email" defaultValue={entryData?.email || ''} required />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input type="password" name="password" defaultValue={entryData?.password || ''} required />
            </div>
            <div className="form-group">
              <label>Connection:</label>
              <input type="text" name="connection" defaultValue="Username-Password-Authentication" required />
            </div>
          </>
        );

      // New Role management case
      case 'Role':
        return (
          <>
            <div className="form-group">
              <label>Role Name:</label>
              <input type="text" name="name" defaultValue={entryData?.name || ''} required />
            </div>
            <div className="form-group">
              <label>Description:</label>
              <input type="text" name="description" defaultValue={entryData?.description || ''} required />
            </div>
          </>
        );

      default:
        return <p>Invalid entry type</p>;
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{isEdit ? `Edit ${type}` : `Add New ${type}`}</h2>
        <form onSubmit={handleSubmit}>
          {renderFormFields()}  {/* Dynamically render form fields based on type */}
          <div className="modal-buttons">
            <button type="submit">{isEdit ? 'Save Changes' : 'Add Entry'}</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
