// ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const [userRoles, setUserRoles] = useState([]);
  const [isFetchingRoles, setIsFetchingRoles] = useState(true); // Add loading state for role fetching

  useEffect(() => {
    const fetchRoles = async () => {
      if (isAuthenticated) {
        const claims = await getIdTokenClaims();
        console.log('Token Claims:', claims); // Debugging
        const roles = claims['https://asobahost.com/roles'] || [];  // Replace with your actual namespace
        setUserRoles(roles);
        setIsFetchingRoles(false);
        console.log('User Roles:', roles); // Debugging
      } else {
        setIsFetchingRoles(false);
      }
    };

    fetchRoles();
  }, [isAuthenticated, getIdTokenClaims]);

  if (isLoading || isFetchingRoles) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    // Redirect unauthenticated users to the landing page
    return <Navigate to="/" />;
  }

  // Check if user has the required role
  const hasRequiredRole = allowedRoles.some(role => userRoles.includes(role));
  console.log('Has Required Role:', hasRequiredRole); // Debugging

  if (!hasRequiredRole) {
    // Redirect to an unauthorized page or handle lack of permissions
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default ProtectedRoute;
