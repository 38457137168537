import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, Navigate } from 'react-router-dom'; // Using Navigate directly
import { useAuth0 } from '@auth0/auth0-react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Dashboard from './components/Dashboard';
import OnDemand from './components/OnDemand';
import Forecasting from './components/Forecasting';
import Dispatch from './components/Dispatch';
import Settings from './components/Settings';
import Login from './components/Login';
import Documentation from './components/Documentation';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';

const App = () => {
  const { logout, isAuthenticated, getIdTokenClaims } = useAuth0();
  const [userRoles, setUserRoles] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isFetchingRoles, setIsFetchingRoles] = useState(true);

  // Fetch roles after login
  useEffect(() => {
    const fetchRoles = async () => {
      if (isAuthenticated) {
        const claims = await getIdTokenClaims();
        const roles = claims['https://asobahost.com/roles'] || [];
        setUserRoles(roles);
      }
      setIsFetchingRoles(false);
    };

    fetchRoles();
  }, [isAuthenticated, getIdTokenClaims]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const hasRole = (allowedRoles) => {
    return allowedRoles.some(role => userRoles.includes(role));
  };

  return (
    <Router>
      {isFetchingRoles ? (
        <div>Loading...</div>
      ) : (
        <Routes>
          {/* Redirect to /dashboard if authenticated */}
          <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />

          {/* Main authenticated area */}
          <Route
            path="/*"
            element={
              <div className={`app ${isCollapsed ? 'collapsed' : ''}`}>
                <div className="topbar">
                  <div className="navbar-logo-container">
                    <img src="/primarylogo.png" alt="Logo" />
                    <span>Asoba</span>
                  </div>
                </div>
                <nav className="navbar">
                  <ul>
                    <li className="collapse-icon">
                      <button onClick={toggleCollapse} className="collapse-button">
                        {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
                      </button>
                    </li>

                    {hasRole(['demandforecast-beta', 'admin']) && (
                      <li>
                        <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
                          Dashboard
                        </NavLink>
                      </li>
                    )}

                    {hasRole(['ondemand-alpha','admin']) && (
                      <li>
                        <NavLink to="/on-demand" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
                          On-Demand
                        </NavLink>
                      </li>
                    )}

                    {hasRole(['demandforecast-beta', 'admin']) && (
                      <li>
                        <NavLink to="/forecasting" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
                          Forecasting
                        </NavLink>
                      </li>
                    )}

                    {hasRole(['demandforecast-beta', 'admin']) && (
                      <li>
                        <NavLink to="/dispatch" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
                          Dispatch
                        </NavLink>
                      </li>
                    )}

                    {hasRole(['ondemand-alpha', 'admin', 'demandforecast-beta']) && (
                      <li>
                        <NavLink to="/settings" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
                          Settings
                        </NavLink>
                      </li>
                    )}

                    {hasRole(['ondemand-alpha', 'admin', 'demandforecast-beta']) && (
                      <li>
                        <NavLink to="/documentation" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
                          Documentation
                        </NavLink>
                      </li>
                    )}

                    <li className="logout-item">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          logout({ returnTo: process.env.REACT_APP_LOGOUT_URL });
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="main-content">
                  <Routes>
                    {/* Alpha Group */}
                    <Route
                      path="/settings"
                      element={
                        <ProtectedRoute allowedRoles={['ondemand-alpha', 'admin', 'demandforecast-beta']}>
                          <Settings />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/on-demand"
                      element={
                        <ProtectedRoute allowedRoles={['ondemand-alpha', 'admin']}>
                          <OnDemand />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/documentation"
                      element={
                        <ProtectedRoute allowedRoles={['ondemand-alpha', 'admin', 'demandforecast-beta']}>
                          <Documentation />
                        </ProtectedRoute>
                      }
                    />

                    {/* Beta Group */}
                    <Route
                      path="/dashboard"
                      element={
                        <ProtectedRoute allowedRoles={['demandforecast-beta', 'admin']}>
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/forecasting"
                      element={
                        <ProtectedRoute allowedRoles={['demandforecast-beta', 'admin']}>
                          <Forecasting isCollapsed={isCollapsed} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dispatch"
                      element={
                        <ProtectedRoute allowedRoles={['demandforecast-beta', 'admin']}>
                          <Dispatch />
                        </ProtectedRoute>
                      }
                    />

                    {/* Admin Group */}
                    <Route
                      path="/settings"
                      element={
                        <ProtectedRoute allowedRoles={['admin']}>
                          <Settings />
                        </ProtectedRoute>
                      }
                    />

                    {/* Fallback route to redirect unhandled paths to /dashboard */}
                    <Route path="*" element={<Dashboard />} />
                  </Routes>
                </div>
              </div>
            }
          />
        </Routes>
      )}
    </Router>
  );
};

export default App;
