import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

// Define the hierarchy data for the grid markers, including load and production data
const markers = [
  {
    id: 1,
    name: "Substation 1",
    position: [27.0473, -26.2041], 
    city: "Johannesburg",
    deviceType: "Substation",
    hierarchyLevel: 1,
    zoomLevel: 4, 
    loadData: [10000, 12000, 11000, 11500, 13000, 12500, 14000, 14500, 15000, 15500, 16000, 16500, 17000, 17500, 18000, 18500, 19000, 19500, 20000, 20500, 21000, 21500, 22000, 22500],
    productionData: null 
  },
  {
    id: 2,
    name: "Mini-Substation 1",
    position: [29.0549, -26.2025],
    city: "Johannesburg",
    deviceType: "Mini-Substation",
    hierarchyLevel: 2,
    zoomLevel: 4, 
    loadData: [5000, 6000, 5500, 5750, 6500, 6250, 7000, 7250, 7500, 7750, 8000, 8250, 8500, 8750, 9000, 9250, 9500, 9750, 10000, 10250, 10500, 10750, 11000, 11250],
    productionData: null 
  },
  {
    id: 3,
    name: "Feeder 1",
    position: [28.9445, -26.1958],
    city: "Johannesburg",
    deviceType: "Feeder",
    hierarchyLevel: 3,
    zoomLevel: 6, 
    loadData: [3000, 3500, 3300, 3450, 3900, 3700, 4100, 4300, 4500, 4700, 4900, 5100, 5300, 5500, 5700, 5900, 6100, 6300, 6500, 6700, 6900, 7100, 7300, 7500],
    productionData: null 
  },
  {
    id: 4,
    name: "Inverter 1",
    position: [28.100, -26.1892],
    city: "Johannesburg",
    deviceType: "Inverter",
    hierarchyLevel: 6,
    zoomLevel: 8, 
    loadData: [1500, 1600, 1550, 1575, 1700, 1650, 1800, 1850, 1900, 1950, 2000, 2050, 2100, 2150, 2200, 2250, 2300, 2350, 2400, 2450, 2500, 2550, 2600, 2650],
    productionData: [1300, 1400, 1350, 1375, 1500, 1450, 1600, 1650, 1700, 1750, 1800, 1850, 1900, 1950, 2000, 2050, 2100, 2150, 2200, 2250, 2300, 2350, 2400, 2450]
  },
  {
    id: 5,
    name: "Smart Meter 1",
    position: [27.8765, -26.1826],
    city: "Johannesburg",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8, 
    loadData: [500, 600, 550, 575, 650, 625, 700, 725, 750, 775, 800, 825, 850, 875, 900, 925, 950, 975, 1000, 1025, 1050, 1075, 1100, 1125],
    productionData: null 
  },
  {
    id: 6,
    name: "Substation 2",
    position: [20.7232, -33.9249], // Cape Town
    city: "Cape Town",
    deviceType: "Substation",
    hierarchyLevel: 1,
    zoomLevel: 4, 
    loadData: [12000, 14000, 13500, 14500, 16000, 15500, 17000, 17500, 18000, 18500, 19000, 19500, 20000, 21000, 22000, 23000, 24000, 24500, 25000, 25500, 26000, 26500, 27000, 27500],
    productionData: null 
  },
  {
    id: 7,
    name: "Mini-Substation 2",
    position: [19.5280, -33.9220], // Cape Town
    city: "Cape Town",
    deviceType: "Mini-Substation",
    hierarchyLevel: 2,
    zoomLevel: 6, 
    loadData: [6000, 6500, 6250, 6750, 7500, 7250, 8000, 8250, 8500, 8750, 9000, 9250, 9500, 9750, 10000, 10250, 10500, 10750, 11000, 11250, 11500, 11750, 12000, 12250],
    productionData: null 
  },
  {
    id: 8,
    name: "Mini-Substation 3",
    position: [18.1235, -33.1291], // Cape Town
    city: "Cape Town",
    deviceType: "Mini-Substation",
    hierarchyLevel: 2,
    zoomLevel: 6, 
    loadData: [5500, 6000, 5750, 6250, 7000, 6750, 7500, 7750, 8000, 8250, 8500, 8750, 9000, 9250, 9500, 9750, 10000, 10250, 10500, 10750, 11000, 11250, 11500, 11750],
    productionData: null 
  },
  {
    id: 9,
    name: "Feeder 2",
    position: [18.993, -33.123], // Cape Town
    city: "Cape Town",
    deviceType: "Feeder",
    hierarchyLevel: 3,
    zoomLevel: 6, 
    loadData: [4000, 4500, 4300, 4450, 4900, 4700, 5100, 5300, 5500, 5700, 5900, 6100, 6300, 6500, 6700, 6900, 7100, 7300, 7500, 7700, 7900, 8100, 8300, 8500],
    productionData: null 
  },
  {
    id: 10,
    name: "Inverter 2",
    position: [20.0000, -33.8888], // Cape Town
    city: "Cape Town",
    deviceType: "Inverter",
    hierarchyLevel: 4,
    zoomLevel: 8, 
    loadData: [2000, 2100, 2050, 2075, 2200, 2150, 2300, 2350, 2400, 2450, 2500, 2550, 2600, 2650, 2700, 2750, 2800, 2850, 2900, 2950, 3000, 3050, 3100, 3150],
    productionData: [1800, 1900, 1850, 1875, 2000, 1950, 2100, 2150, 2200, 2250, 2300, 2350, 2400, 2450, 2500, 2550, 2600, 2650, 2700, 2750, 2800, 2850, 2900, 2950]
  },
  {
    id: 11,
    name: "Meter 2",
    position: [18.9999, -33.4454], // Cape Town
    city: "Cape Town",
    deviceType: "Meter",
    hierarchyLevel: 5,
    zoomLevel: 8, 
    loadData: [800, 900, 850, 875, 950, 925, 1000, 1025, 1050, 1075, 1100, 1125, 1150, 1175, 1200, 1225, 1250, 1275, 1300, 1325, 1350, 1375, 1400, 1425],
    productionData: null 
  },
  {
    id: 12,
    name: "Meter 3",
    position: [18.666, -32.9275], // Cape Town
    city: "Cape Town",
    deviceType: "Meter",
    hierarchyLevel: 5,
    zoomLevel: 8, 
    loadData: [750, 850, 800, 825, 900, 875, 950, 975, 1000, 1025, 1050, 1075, 1100, 1125, 1150, 1175, 1200, 1225, 1250, 1275, 1300, 1325, 1350, 1375],
    productionData: null 
  },
  {
    id: 13,
    name: "Meter 4",
    position: [18.8888, -33.3333], // Cape Town
    city: "Cape Town",
    deviceType: "Meter",
    hierarchyLevel: 5,
    zoomLevel: 8, 
    loadData: [700, 800, 750, 775, 850, 825, 900, 925, 950, 975, 1000, 1025, 1050, 1075, 1100, 1125, 1150, 1175, 1200, 1225, 1250, 1275, 1300, 1325],
    productionData: null 
  },
  {
    id: 14,
    name: "Meter 5",
    position: [20.0000, -31.9280], // Cape Town
    city: "Cape Town",
    deviceType: "Meter",
    hierarchyLevel: 5,
    zoomLevel: 8, 
    loadData: [750, 850, 800, 825, 900, 875, 950, 975, 1000, 1025, 1050, 1075, 1100, 1125, 1150, 1175, 1200, 1225, 1250, 1275, 1300, 1325, 1350, 1375],
    productionData: null 
  },
  {
    id: 15,
    name: "Meter 6",
    position: [18.4310, -34.1290], // Cape Town
    city: "Cape Town",
    deviceType: "Meter",
    hierarchyLevel: 5,
    zoomLevel: 8, 
    loadData: [650, 750, 700, 725, 800, 775, 850, 875, 900, 925, 950, 975, 1000, 1025, 1050, 1075, 1100, 1125, 1150, 1175, 1200, 1225, 1250, 1275],
    productionData: null 
  }
];

const getColorForDeviceType = (deviceType) => {
    switch (deviceType) {
      case 'Substation':
        return '#FF0000'; // Red for substations
      case 'Mini-Substation':
        return '#FFA500 '; // Orange for mini-substations
      case 'Feeder':
        return '#00FF00'; // Green for feeders
      case 'Inverter':
        return '#0000FF'; // Blue for inverters
      case 'Meter':
        return '#FFFF00'; // Yellow for meters
      default:
        return '#FFFFFF'; // Default color
    }
  };
  
  const MarkersComponent = ({ map, onMarkerClick }) => {
    useEffect(() => {
      if (!map || !map.getContainer()) return; // Ensure the map container is available
  
      const markerInstances = []; // Store marker instances for proper cleanup
  
      const addMarkers = () => {
        markers.forEach(marker => {
          // Check if the current zoom level matches or exceeds the marker's zoom level
          if (map.getZoom() >= marker.zoomLevel) {
            // Create a marker instance with popup
            const markerInstance = new mapboxgl.Marker({ color: getColorForDeviceType(marker.deviceType) })
              .setLngLat(marker.position)
              .setPopup(
                new mapboxgl.Popup({ offset: 25 })
                  .setHTML(`
                    <h3>${marker.name}</h3>
                    <p>City: ${marker.city}</p>
                    <p>Network Level: ${marker.deviceType}</p>
                    <p>Avg Hourly Load: ${calculateAverageLoad(marker.loadData)}</p>
                  `)
              )
              .addTo(map); // Add the marker and popup to the map
  
            // Default Mapbox popup behavior on click
            markerInstance.getElement().addEventListener('click', () => {
              onMarkerClick(marker); // Pass marker data to the parent component
            });
  
            markerInstances.push(markerInstance); // Store for cleanup
          }
        });
      };
  
      const handleZoomChange = () => {
        // Clear existing markers
        markerInstances.forEach(markerInstance => markerInstance.remove());
        markerInstances.length = 0; // Reset the array
  
        // Re-add markers based on the new zoom level
        addMarkers();
      };
  
      // Add markers initially based on the zoom level
      addMarkers();
  
      // Listen for zoom changes
      map.on('zoom', handleZoomChange);
  
      // Cleanup function to remove the markers when the component unmounts
      return () => {
        map.off('zoom', handleZoomChange); // Remove zoom listener
        markerInstances.forEach(markerInstance => markerInstance.remove()); // Properly remove all markers
      };
    }, [map, onMarkerClick]);
  
    return null;
  };
  
  // Helper function to calculate the average load
  const calculateAverageLoad = (loadData) => {
    const sum = loadData.reduce((acc, value) => acc + value, 0);
    return Math.round(sum / loadData.length);
  };
  
  
  
  export default MarkersComponent;