import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(...registerables, annotationPlugin);

const ForecastGraphComponent = ({ activeTab, loadData, productionData }) => {
    const chartRef = useRef(null);
    const myChartRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        // Destroy existing chart instance before creating a new one
        if (myChartRef.current) {
            myChartRef.current.destroy();
        }

        // Get the data for the current tab (load or production)
        const data = activeTab === 'load' ? loadData : productionData;

        // Calculate the min and max values dynamically
        const minValue = Math.min(...data);
        const maxValue = Math.max(...data);

        const chartData = {
            labels: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
            datasets: [{
                label: activeTab === 'load' ? 'Load' : 'Production',
                data: data,
                borderColor: '#455BF1',
                borderWidth: 2,
                fill: false,
                tension: activeTab === 'load' ? 0.4 : 0.2, // Different curve tension for Load and Production
            }]
        };

        const chartOptions = {
            scales: {
                x: {
                    type: 'category',
                    ticks: {
                        color: '#333'
                    }
                },
                y: {
                    beginAtZero: true,
                    min: minValue - 100, // Add a little padding below the minimum value
                    max: maxValue + 100, // Add a little padding above the maximum value
                    ticks: {
                        color: '#333'
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                annotation: {
                    annotations: {
                        offPeak: {
                            type: 'box',
                            xMin: 0,
                            xMax: 5.5,
                            backgroundColor: 'rgba(46, 204, 113, 0.2)',
                            borderColor: 'rgba(46, 204, 113, 1)',
                            borderWidth: 1,
                            label: {
                                content: 'Off-peak',
                                enabled: true,
                                position: 'start'
                            }
                        },
                        standardMorning: {
                            type: 'box',
                            xMin: 5.5,
                            xMax: 9.5,
                            backgroundColor: 'rgba(241, 196, 15, 0.2)',
                            borderColor: 'rgba(241, 196, 15, 1)',
                            borderWidth: 1,
                            label: {
                                content: 'Standard',
                                enabled: true,
                                position: 'start'
                            }
                        },
                        peak: {
                            type: 'box',
                            xMin: 9.5,
                            xMax: 17.5,
                            backgroundColor: 'rgba(231, 76, 60, 0.2)',
                            borderColor: 'rgba(231, 76, 60, 1)',
                            borderWidth: 1,
                            label: {
                                content: 'Peak',
                                enabled: true,
                                position: 'start'
                            }
                        },
                        standardEvening: {
                            type: 'box',
                            xMin: 17.5,
                            xMax: 21.5,
                            backgroundColor: 'rgba(241, 196, 15, 0.2)',
                            borderColor: 'rgba(241, 196, 15, 1)',
                            borderWidth: 1,
                            label: {
                                content: 'Standard',
                                enabled: true,
                                position: 'start'
                            }
                        },
                        offPeakEvening: {
                            type: 'box',
                            xMin: 21.5,
                            xMax: 24,
                            backgroundColor: 'rgba(46, 204, 113, 0.2)',
                            borderColor: 'rgba(46, 204, 113, 1)',
                            borderWidth: 1,
                            label: {
                                content: 'Off-peak',
                                enabled: true,
                                position: 'start'
                            }
                        }
                    }
                }
            }
        };

        myChartRef.current = new Chart(ctx, {
            type: 'line',
            data: chartData,
            options: chartOptions
        });

        return () => {
            // Clean up the chart instance on component unmount
            if (myChartRef.current) {
                myChartRef.current.destroy();
            }
        };
    }, [activeTab, loadData, productionData]);  // Re-render the chart when any of these change

    return (
        <div>
            <canvas ref={chartRef}></canvas>
            <div className="legend">
                <span style={{ color: 'rgba(46, 204, 113, 1)' }}>● Off-peak</span> | 
                <span style={{ color: 'rgba(241, 196, 15, 1)' }}>● Standard</span> | 
                <span style={{ color: 'rgba(231, 76, 60, 1)' }}>● Peak</span>
            </div>
        </div>
    );
};

export default ForecastGraphComponent;
