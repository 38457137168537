import React from 'react';
import { createRoot } from 'react-dom/client';  // import createRoot for React 18
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

// Auth0 configuration
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

// Define custom onRedirectCallback to navigate to /dashboard after login
const onRedirectCallback = (appState) => {
  window.location.href = appState?.returnTo || '/dashboard';
};

// Use createRoot to render the app for React 18
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{ redirect_uri: window.location.origin + '/dashboard' }}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
