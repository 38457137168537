import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkSlug from 'remark-slug';
import '../App.css';

const Documentation = () => {
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    fetch('/documentation.md')
      .then((response) => response.text())
      .then((text) => setMarkdownContent(text));
  }, []);

  return (
    <div className="markdown-container">
      {/* Navigation Panel */}
      <nav className="markdown-nav">
      <ul>
        <li><a href="#introduction">Introduction</a></li>
        <li><a href="#api-endpoints">API Endpoints</a></li>
        <ul>
            <li><a href="#1-ingestdata">Data Ingest</a></li>
            <li><a href="#2-ingestnowcastloaddata">Nowcasting</a></li>
            <li><a href="#3-ondemandforecast">On-Demand Forecast</a></li>
            <li><a href="#4-ondemanddispatch">On-Demand Dispatch</a></li>

        </ul>
        <li><a href="#error-handling">Error Handling</a></li>
        <li><a href="#conclusion">Conclusion</a></li>
    </ul>
      </nav>

      {/* Markdown Content */}
      <div className="markdown-content">
        <ReactMarkdown remarkPlugins={[remarkSlug]}>{markdownContent}</ReactMarkdown>
      </div>
    </div>
  );
};

export default Documentation;



