// src/components/Dashboard.js
import React from 'react';

function Dispatch() {
  return (
    <div>
      <h2>Dispatch</h2>
      <p>This is the Dispatch content.</p>
    </div>
  );
}

export default Dispatch;
